<template>
  <div class="lista-permicao" style="padding-right: 130px;">
    <p class="form-title m-3">Colaboradores</p>
    <div class="d-flex justify-content-between">
      <div>
        <p>Colaborador</p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Visualizar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckChecked"> Criar </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Editar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Apagar
          </label>
        </div>

        <br />

        <p>Dependentes</p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Visualizar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckChecked"> Criar </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Editar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Apagar
          </label>
        </div>
        <br />
      </div>
      <div>
        <p>Contratação</p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Visualizar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckChecked"> Criar </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Editar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Apagar
          </label>
        </div>

        <br />

        <p>Acesso</p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Visualizar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckChecked"> Criar </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Editar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Apagar
          </label>
        </div>

        <br />
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "ColaboradoresCia",
};
</script>
