<template>
  <div class="lista-permicao">
    <p class="form-title m-3">Beneficiarios</p>
    <div class="d-flex justify-content-around">
      <div>
        <p>Cadastro Contrato para Beneficiario</p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Visualizar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckChecked"> Criar </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Editar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Apagar
          </label>
        </div>

        <br />

        <p>Consultando Beneficiario</p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Visualizar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckChecked"> Criar </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Editar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Apagar
          </label>
        </div>

        <br />

        <p>COBRAR/BAIXAR/GERAR</p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Visualizar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckChecked"> Criar </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Editar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Apagar
          </label>
        </div>
        <br />
      </div>
      <div>
        <p>Planos Asistencia</p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Visualizar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckChecked"> Criar </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Editar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Apagar
          </label>
        </div>

        <br />

        <p>Analisando Beneficiarios</p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Visualizar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckChecked"> Criar </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Editar
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            Apagar
          </label>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "PlanosAssistencia",
};
</script>
