<template>
  <div v-if="OpenClose">
    <div class="modal-mask-img-upload">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header"></div>
          <div class="modal-body">
            <img
              v-if="listImg[this.campoImagem]"
              :src="listImg[this.campoImagem]"
              style="width: 410px"
            />
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              @click="openCloseModal()"
              class="btn-blue btn-sm"
              style="width: 70px"
            >
              Fechar
            </button>

            <button type="button" @click="removeImage()" class="btn-red">
              Remover
            </button>

            <label for="file-upload" class="custom-file-upload">Buscar</label>

            <input
              type="file"
              id="file-upload"
              @change="onFileChange(item, $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";

export default {
  name: "ShowImage",

  props: {
    visible: Boolean,
    showView: String,
    deleteMode: Boolean,
    msg: String,
    sendForm: Boolean,
    newcard: String,
    infoTanato: Object,
  },

  data() {
    return {
      OpenClose: this.visible,

      ShowButtonDelete: this.showView,

      campoImagem: "",

      listImg: {
        decomposicao: "",
        rigidez: "",
        odor: "",
        olhos: "",
        boca: "",
        ouvido: "",
        toracoabdominal: "",
      },
    };
  },

  methods: {
    openCloseModal() {
      this.OpenClose = false;

      this.$emit("set-imagens", this.listImg);
    },

    onFileChange(item, e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;

      this.listImg[this.campoImagem] = e.target.files[0];

      this.createImage(item, files[0]);
    },

    createImage(item, file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.listImg[this.campoImagem] = e.target.result;
      };

      reader.readAsDataURL(file);
    },

    removeImage() {
      this.listImg[this.campoImagem] = "";
    },
  },

  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
      console.log("new " + newVal + "==" + oldVal);
    },

    msg: function (msg) {
      this.campoImagem = msg;
    },

    infoTanato: function (item) {
      const tanato = JSON.parse(JSON.stringify(item));

      if (tanato) {
        axios
          .get(`${process.env.VUE_APP_API_URL}/tanatorio/get-imagens/${tanato}`)
          .then((res) => {
            this.listImg["decomposicao"] = res.data[0].imagem_decomposicao
              ? `${process.env.VUE_APP_STORAGE}/storage/img/${res.data[0].imagem_decomposicao}`
              : "";
            this.listImg["rigidez"] = res.data[0].imagem_rigidez
              ? `${process.env.VUE_APP_STORAGE}/storage/img/${res.data[0].imagem_rigidez}`
              : "";
            this.listImg["odor"] = res.data[0].imagem_odor
              ? `${process.env.VUE_APP_STORAGE}/storage/img/${res.data[0].imagem_odor}`
              : "";
            this.listImg["olhos"] = res.data[0].imagem_olhos
              ? `${process.env.VUE_APP_STORAGE}/storage/img/${res.data[0].imagem_olhos}`
              : "";
            this.listImg["boca"] = res.data[0].imagem_boca
              ? `${process.env.VUE_APP_STORAGE}/storage/img/${res.data[0].imagem_boca}`
              : "";
            this.listImg["ouvido"] = res.data[0].imagem_ouvido
              ? `${process.env.VUE_APP_STORAGE}/storage/img/${res.data[0].imagem_ouvido}`
              : "";
            this.listImg["toracoabdominal"] = res.data[0].imagem_toracoabdominal
              ? `${process.env.VUE_APP_STORAGE}/storage/img/${res.data[0].imagem_toracoabdominal}`
              : "";
          });
      }
    },
  },
};
</script>
